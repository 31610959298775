<template>
  <div>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0 d-none d-md-block">Chargement des formations</h2>
    </div>
    <div v-else>
      <div class="rounded p-3 mb-3" v-background-3>
        <div>
          <h4 class="m-0">Plan de développement des compétences</h4>
          <p class="text-muted">Nombre d'actions de formation</p>
        </div>
        <apexchart ref="chart" type="bar" height="460" :options="chartOptions" :series="series.data"></apexchart>
      </div>
      <div class="rounded p-3" v-background-3>
        <div>
          <h4 class="m-0">Plan de développement des compétences</h4>
          <p class="text-muted">Nombres d'heures moyens</p>
        </div>
        <apexchart ref="chart" type="bar" height="460" :options="chartOptions2" :series="series2.data"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/bases/Loader.vue";

export default {
  data() {
    return {
      formations: [],
      loading: false,
    };
  },
  components: {
    Loader,
  },
  mounted() {
    this.loading = true;
    this.$http
      .get("/plan_formation_cse")
      .then((response) => (this.formations = response.data))
      .finally(() => (this.loading = false));
  },
  computed: {
    series() {
      let debut = this.$moment().endOf("month").subtract(2, "year");
      let fin = this.$moment().endOf("month").add(1, "year");
      let output1 = [];
      let output2 = [];
      let labels = [];

      if (!this.formations) return {};

      while (debut < fin) {
        let frmObligatoires = 0;
        let frmNonObligatoires = 0;

        this.formations.forEach((frm) => {
          if (
            this.$moment(frm.formation_date_debut).format("YYYY-MM") == this.$moment(debut).format("YYYY-MM") &&
            frm.formation_obligatoire == true
          )
            frmObligatoires++;
          if (
            this.$moment(frm.formation_date_debut).format("YYYY-MM") == this.$moment(debut).format("YYYY-MM") &&
            (frm.formation_obligatoire == false || frm.formation_obligatoire == null)
          )
            frmNonObligatoires++;
        });

        output1.push(frmObligatoires);
        output2.push(frmNonObligatoires);
        labels.push(debut.format("MMMM YYYY"));
        debut.add(1, "month").endOf("month");
      }

      return {
        labels: labels,
        data: [
          { name: "Formations obligatoires", data: output1, color: "#007bff" },
          { name: "Formations non obligatoires", data: output2, color: "#dc3545" },
        ],
      };
    },
    series2() {
      let debut = this.$moment().endOf("month").subtract(2, "year");
      let fin = this.$moment().endOf("month").add(1, "year");
      let output1 = [];
      let output2 = [];
      let labels = [];

      if (!this.formations) return {};

      while (debut < fin) {
        let nbHeureH = 0;
        let nbHeureF = 0;
        let cpt = 0;

        this.formations.forEach((frm) => {
          if (
            this.$moment(frm.formation_date_debut).format("YYYY-MM") == this.$moment(debut).format("YYYY-MM") &&
            frm.collaborateur_lt.collaborateur_sexe_id == 1
          ) {
            cpt++;
            nbHeureH = nbHeureH + frm.formation_nbre_heures;
          }

          if (
            this.$moment(frm.formation_date_debut).format("YYYY-MM") == this.$moment(debut).format("YYYY-MM") &&
            frm.collaborateur_lt.collaborateur_sexe_id == 2
          ) {
            cpt++;
            nbHeureF = nbHeureF + frm.formation_nbre_heures;
          }
        });

        if (cpt == 0) cpt = 1;

        output1.push((nbHeureH / cpt).toFixed(2));
        output2.push((nbHeureF / cpt).toFixed(2));
        labels.push(debut.format("MMMM YYYY"));
        debut.add(1, "month").endOf("month");
      }

      return {
        labels: labels,
        data: [
          { name: "Homme", data: output1, color: "#007bff" },
          { name: "Femme", data: output2, color: "#dc3545" },
        ],
      };
    },
    chartOptions() {
      if (!this.formations) return {};
      return {
        colors: ["#EB9D00"],
        chart: {
          background: "transparent",
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: "zoom",
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        theme: {
          mode: this.$store.getters["colors/darkMode"] ? "dark" : "light",
        },
        stroke: {
          width: 4,
          curve: "smooth",
        },
        xaxis: {
          categories: this.series.labels,
        },
        fill: {
          opacity: 0.8,
        },
        markers: {
          size: 4,
          strokeColors: "#fff",
          strokeWidth: 0,
          hover: {
            size: 7,
          },
        },
        legend: {
          show: true,
          position: "right",
          showForZeroSeries: true,
          itemMargin: {
            horizontal: 0,
            vertical: 3,
          },
          markers: {
            width: 10,
            height: 10,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " formation(s)";
            },
          },
        },
      };
    },
    chartOptions2() {
      if (!this.formations) return {};
      return {
        colors: ["#EB9D00"],
        chart: {
          background: "transparent",
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
            autoSelected: "zoom",
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        theme: {
          mode: this.$store.getters["colors/darkMode"] ? "dark" : "light",
        },
        stroke: {
          width: 4,
          curve: "smooth",
        },
        xaxis: {
          categories: this.series.labels,
        },
        fill: {
          opacity: 0.8,
        },
        markers: {
          size: 4,
          strokeColors: "#fff",
          strokeWidth: 0,
          hover: {
            size: 7,
          },
        },
        legend: {
          show: true,
          position: "right",
          showForZeroSeries: true,
          itemMargin: {
            horizontal: 0,
            vertical: 3,
          },
          markers: {
            width: 10,
            height: 10,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " heure(s)";
            },
          },
        },
      };
    },
  },
};
</script>
