<template>
  <div>
    <rh></rh>
    <Formation></Formation>
  </div>
</template>
<script>
import Rh from "@/components/cse/Rh.vue";
import Formation from "@/components/cse/Formation.vue";

export default {
  components: { Rh, Formation },
  methods: {},
  mounted() {},
  computed: {},
};
</script>
