<template>
  <div>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0 d-none d-md-block">Chargement des données RH</h2>
    </div>
    <div v-else>
      <div class="d-flex mb-3">
        <download-excel
          :data="collaborateursExport"
          class="btn btn-primary cursor-pointer ml-auto"
          name="collaborateurs.xls"
        >
          Exporter (.xls)
          <span class="badge badge-warning">{{ collaborateursExport.length }}</span>
        </download-excel>
      </div>

      <effectifs :collaborateurs="collaborateurs" :collaborateursData="collaborateursData"></effectifs>
      <salaires :collaborateurs="collaborateurs" :collaborateursData="collaborateursData"></salaires>
      <div class="d-flex justify-content-between">
        <temps-partiel
          class="w-100 mr-3"
          :collaborateursData="collaborateursData"
          :collaborateursLastContrats="collaborateursLastContrats"
        ></temps-partiel>
        <temps-travail
          class="w-100"
          :collaborateursData="collaborateursData"
          :collaborateursLastContrats="collaborateursLastContrats"
        ></temps-travail>
      </div>
      <div>
        <hf
          class="w-100"
          :collaborateursData="collaborateursData"
          :collaborateursLastContrats="collaborateursLastContrats"
        ></hf>
      </div>
      <div class="rounded mb-3" v-background-3>
        <cdd :collaborateursData="collaborateursData" :collaborateursLastContrats="collaborateursLastContrats"></cdd>
      </div>
      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Heures supplémentaires accomplies</h4>
        <uploader
          :id="'document_conges'"
          url="/upload/document"
          file_system="CSE/HEURES"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>
      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Articulation vie professionnelle et familiale</h4>
        <uploader
          :id="'document_conges'"
          url="/upload/document"
          file_system="CSE/CONGES"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>
      <div class="rounded p-3 mb-3" v-background-3>
        <h4>Rapport annuel SSER</h4>
        <uploader
          :id="'document_sser'"
          url="/upload/document"
          file_system="CSE/SSER"
          file_field="rapport"
          :file_key="1"
          title="Liste des fichiers"
        ></uploader>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import tempsPartiel from "@/components/cse/RhTempsPartiel.vue";
import tempsTravail from "@/components/cse/RhTempsTravail.vue";
import effectifs from "@/components/cse/RhEffectifs.vue";
import salaires from "@/components/cse/RhSalaireMoyen.vue";
import hf from "@/components/cse/RhHF.vue";
import cdd from "@/components/cse/RhCdd.vue";
import Loader from "@/components/bases/Loader.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  data() {
    return {
      collaborateurs: [],
      collaborateursData: {},
      loading: false,
      loading2: false,
    };
  },
  components: {
    tempsPartiel,
    tempsTravail,
    effectifs,
    hf,
    Loader,
    Uploader,
    cdd,
    salaires,
  },
  methods: {
    ...mapActions({
      //_getCollaborateursAll: "collaborateurs/getCollaborateursAll",
      _getData: "collaborateurs/getData",
    }),
    getCollaborateursAll() {
      this.loading = true;
      this.$http
        .get("/effectifs_cse")
        .then((data) => (this.collaborateurs = data.data))
        .finally(() => (this.loading = false));
    },
    getData() {
      this.loading2 = true;
      this._getData()
        .then((data) => (this.collaborateursData = data.data))
        .finally(() => (this.loading2 = false));
    },
    descriptionById(list, id) {
      const index = this.collaborateursData[list].findIndex((x) => x.id === id);
      if (this.collaborateursData && this.collaborateursData[list][index])
        return this.collaborateursData[list][index].description;
      else return "";
    },
  },
  mounted() {
    this.getCollaborateursAll();
    this.getData();
  },
  computed: {
    collaborateursFiltered() {
      if (!this.collaborateurs) return [];
      let rep = this.collaborateurs;
      if (rep) rep = rep.filter((collab) => collab.collaborateur_actif == true);
      return rep;
    },
    collaborateursLastContrats() {
      if (!this.collaborateurs) return [];
      let contrats = [];

      const collabs = this.collaborateursFiltered;

      if (collabs)
        collabs.forEach((collab) => {
          if (collab.collaborateur_contrats_all && collab.collaborateur_contrats_all.length >= 1) {
            let contratVersions = [];
            //let contratSelected = {}
            let versionSelected = {};

            let __contrat = collab.collaborateur_contrats_all;
            let max_dtObj1 = this.$moment(__contrat[0].contrat_date_debut);
            let indexContratMaxDate = 0;

            // Identifier l'index max sur la date de début
            __contrat.forEach((dt, index) => {
              if (this.$moment(dt.contrat_date_debut) > max_dtObj1) {
                max_dtObj1 = this.$moment(dt.contrat_date_debut);
                indexContratMaxDate = index;
              }
            });

            contratVersions = __contrat[indexContratMaxDate].contrat_version_all;

            // Obtenir la dernière version
            // -----------------------------------------------------------------------------------
            // ANALYSE DES VERSIONS DU CONTRAT
            // -----------------------------------------------------------------------------------

            // Identifier la dernière version si plusieurs versions d'un contrat (sur date d'effet)
            if (contratVersions.length >= 1) {
              let max_dtObj = this.$moment(contratVersions[0].date_effet);
              let indexVersionMaxDate = 0;

              // Identifier l'index max sur la date d'effet
              contratVersions.forEach((dt, index) => {
                if (this.$moment(dt.date_effet) > max_dtObj) {
                  max_dtObj = this.$moment(dt.date_effet);
                  indexVersionMaxDate = index;
                }
              });

              // Dernière version basée sur la date d'effet
              versionSelected = contratVersions[indexVersionMaxDate];

              contrats.push({
                ...versionSelected,
                ...collab,
              });
            }
          }
        });
      return contrats;
    },
    collaborateursExport() {
      const collabs = this.collaborateursLastContrats.filter((collab) => collab.contrat_siret.siret_societe_id === 1);
      return collabs.map((collab) => {
        const output = {};
        output.nom = collab.collaborateur_nom;
        output.prenom = collab.collaborateur_prenom;
        output.mail = collab.collaborateur_email_pro;
        output.statut = this.descriptionById("contratStatus", collab.contrat_statut_id);
        return output;
      });
    },
  },
};
</script>
