<template>
  <div class="rounded p-3 mb-3" v-background-3>
    <h4>Temps de travail</h4>

    <div class="p-3">
      <table class="table table-responsive-lg border" v-background-3 v-color-1>
        <thead>
          <tr>
            <th scope="col" class="text-muted">Total {{ collaborateursFiltered.length }}</th>
            <th scope="col">Effectif</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-left" v-for="temps in tempsdetravail" :key="temps">
            <td class="text-left" scope="row">Temps de travail ({{ temps }}h)</td>
            <td>{{ repartition[temps] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: ["collaborateursData", "collaborateursLastContrats"],
  computed: {
    collaborateursFiltered() {
      return this.collaborateursLastContrats.filter((collab) => collab.contrat_siret.siret_societe_id === 1);
    },

    tempsdetravail() {
      let contrats = this.collaborateursFiltered;
      let output = [];
      if (contrats)
        output = contrats
          .map((item) => item.contrat_temps_travail)
          .filter((value, index, self) => self.indexOf(value) === index);
      return output;
    },

    repartition() {
      const temps = this.tempsdetravail;
      const contrats = this.collaborateursFiltered;

      let output = {};

      if (temps && temps.length > 0) {
        temps.forEach((t) => {
          output[t] = contrats.filter((c) => c.contrat_temps_travail == t).length;
        });
      }
      return output;
    },
  },
};
</script>
